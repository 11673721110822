.footer {
    height: 30.4rem;
    background-color: #FAFAFA;
    padding: 6rem 12rem;
    color: #808080;
}

.companyName {
    font-size: 3.2rem;
    font-weight: bold;
}

.labName {
    font-size: 2.4rem;
    font-weight: 600;
}

.container {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 4rem;
}

.ceo {
    font-size: 1.6rem;
}

.contact {
    font-size: 1.6rem;
}

.location {
    font-size: 1.6rem;
}

@media (max-width: 767px) {
    .footer {
        height: 43.4rem;
        background-color: #FAFAFA;
        padding: 4rem 2.4rem;
        color: #808080;
    }

    .companyName {
        font-size: 3.2rem;
        font-weight: bold;
    }

    .labName {
        font-size: 2.4rem;
        font-weight: 600;
    }

    .container {
        margin-top: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 1.6rem;
    }

    .ceo {
        font-size: 1.6rem;
    }

    .contact {
        font-size: 1.6rem;
    }

    .location {
        font-size: 1.6rem;
    }

    .location span{
        display: inline-block;
    }
}