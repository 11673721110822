.ctaButton{
    width: 19.4rem;
    height: 6.4rem;
    border-radius: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.ctaButtonText{
    font-size: 2.4rem;
}

@media (max-width: 767px) {
    .ctaButton{
        width: 11.4rem;
        height: 4.8rem;
        border-radius: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }
    
    .ctaButtonText{
        font-size: 1.6rem;
    }
  }