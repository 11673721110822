.functionSection {
    margin-top: 24rem;
    margin-bottom: 16rem;
    padding: 0 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 4rem;
    font-weight: bold;
}

.grid {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 한 줄에 2개 */
    gap: 1.6rem;
    /* 아이템 간 간격 */
}

.gridItem {
    border-radius: 2.4rem;
}

.gridItem img {
    width: 100%;
    height: auto;
    display: block;
}

.lastBlock {
    background-color: #FFF2BD;
    border: 1px solid var(--intalk-primary-normal);
    border-radius: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ask {
    font-size: 2.4rem;
    font-weight: 600;
}

.goToKakaoButton {
    width: 19.4rem;
    height: 6.4rem;
    margin-top: 1.6rem;
    background-color: var(--intalk-primary-normal);
    border-radius: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    font-weight: bold;
}

@media (max-width: 767px) {
    .functionSection {
        margin-top: 12rem;
        margin-bottom: 2.4rem;
        padding: 0 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 2rem;
        font-weight: bold;
    }

    .grid {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
    }

    .gridItem {
        border-radius: 1.2rem;
    }

    .gridItem img {
        width: 100%;
        height: auto;
        display: block;
    }

    .lastBlock {
        width: 100%;
        height: calc((100vw - (3.2rem)) / 1.5);
        background-color: #FFF2BD;
        border: 1px solid var(--intalk-primary-normal);
        border-radius: 1.2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ask {
        font-size: 1.8rem;
        font-weight: 600;
    }

    .goToKakaoButton {
        width: 11.9rem;
        height: 4.8rem;
        margin-top: 0.8rem;
        background-color: var(--intalk-primary-normal);
        border-radius: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-weight: bold;
    }
}