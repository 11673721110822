.newsSection {
    margin-top: 16rem;
    padding: 0 12rem;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
}

.ceoImage {
    width: 33.6rem;
    height: 33.6rem;
    border-radius: 1.6rem;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.writer {
    font-size: 2rem;
}

.title {
    margin-top: 0.8rem;
    font-size: 4rem;
    font-weight: bold;
}

.description {
    margin-top: 2.4rem;
    font-size: 1.6rem;
    width: 68.8rem;
}

.goToNewsButton {
    margin-top: 3.2rem;
    width: 7.6rem;
    height: 3.6rem;
    font-size: 1.4rem;
    background-color: var(--intalk-primary-normal);
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .newsSection {
        margin-top: 8rem;
        padding: 0 1.6rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2.4rem;
    }

    .ceoImage {
        width: 100%;
        height: auto;
        border-radius: 1.6rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }

    .writer {
        font-size: 1.6rem;
    }

    .title {
        margin-top: 0.4rem;
        font-size: 2.8rem;
        font-weight: bold;
    }

    .description {
        margin-top: 1.6rem;
        font-size: 1.6rem;
        width: 100%;
    }

    .goToNewsButton {
        margin-top: 2.4rem;
        width: 7.6rem;
        height: 3.6rem;
        font-size: 1.4rem;
        background-color: var(--intalk-primary-normal);
        border-radius: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}