.productSection {
    margin-top: 16rem;
    display: flex;
    flex-direction: column;
    gap: 24rem;
}

.container {
    padding-left: 12rem;
    display: flex;
    flex-direction: row;
    align-items: start;
}

.content {
    flex: 1;
    margin-top: 4rem;
}

.logo {
    width: 4rem;
    height: 4rem;

}

.slogan {
    margin-top: 0.8rem;
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--intalk-text-strong);
}

.title {
    margin-top: 1.2rem;
    font-size: 4.8rem;
    font-weight: bold;
    color: var(--intalk-text-strong);
}

.description {
    margin-top: 3.2rem;
    margin-bottom: 2.4rem;
    font-size: 3.2rem;
    font-weight: 600;
    color: var(--intalk-text-strong);
}

.blank {
    width: 2rem;
}

.imageContainer {
    flex: 2.9;
}

.ui {
    width: 100%;
}

@media (max-width: 767px) {
    .productSection {
        margin-top: 6.4rem;
        display: flex;
        flex-direction: column;
        gap: 8rem;
    }

    .container {
        padding-left: 1.6rem;
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .logo {
        width: 2.4rem;
        height: 2.4rem;

    }

    .slogan {
        margin-top: 0.8rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--intalk-text-strong);
    }

    .title {
        margin-top: 0.2rem;
        font-size: 2.4rem;
        font-weight: bold;
        color: var(--intalk-text-strong);
    }

    .description {
        margin-top: 0.4rem;
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--intalk-text-strong);
    }

    .blank {
        width: 4rem;
    }

    .imageContainer {
        padding: 0 1.6rem;
    }

    .ui {
        width: 100%;
    }
}