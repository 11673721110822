.mainSection {
    box-sizing: border-box;
    padding: 1.6rem 12rem;
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: end;
}

.content {
    flex: 1;
}

.logo {
    width: 4rem;
    height: 4rem;

}

.slogan {
    margin-top: 1.6rem;
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--intalk-text-strong);
}

.title {
    margin-top: 0.4rem;
    margin-bottom: 4rem;
    font-size: 4.8rem;
    font-weight: bold;
    color: var(--intalk-text-strong);
}

.blank {
    width: 5rem;
}

.imageContainer {
    flex: 1.9;
}

.ui {
    width: 100%;
    max-height: calc(100vh - 20rem);
    /* 화면 높이를 넘어가지 않도록 제한 */
    object-fit: contain;
    /* 비율을 유지하며 영역에 맞게 조정 */
}

.scrollInfoContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.scrollInfoText {
    margin-top: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--intalk-primary-strong);
}

.scrollArrow {
    width: 2.4rem;
    margin-top: 1.2rem;
}

@media (max-width: 767px) {
    .mainSection {
        box-sizing: border-box;
        padding: 1.2rem 2rem;
        height: calc(100vh - 6.2rem);
        display: flex;
        flex-direction: column;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .content {
        flex: 1;
        order: 2;
    }

    .logo {
        width: 2.4rem;
        height: 2.4rem;

    }

    .slogan {
        margin-top: 0.8rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--intalk-text-strong);
    }

    .title {
        margin-top: 0.2rem;
        margin-bottom: 1.8rem;
        font-size: 2.4rem;
        font-weight: bold;
        color: var(--intalk-text-strong);
    }

    .blank {
        height: 2rem;
    }

    .imageContainer {
        width: 100%;
        display: flex;
        justify-self: center;
        align-items: center;
        order: 1;
    }

    .ui {
        width: 100%;
        max-height: calc(100vh - 40rem);
        /* 화면 높이를 넘어가지 않도록 제한 */
        object-fit: contain;
        /* 비율을 유지하며 영역에 맞게 조정 */
    }

    .scrollInfoContainer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    .scrollInfoText {
        margin-top: 2.4rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--intalk-primary-strong);
    }

    .scrollArrow {
        width: 2rem;
        margin-top: 0.4rem;
    }
}