.collaboratorSection {
    padding: 0 12rem;
    margin-top: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 4rem;
    font-weight: bold;
    color: var(--intalk-text-strong);
}

.logoContainer {
    margin-top: 2.4rem;
}

.row {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.gridItem {
    text-align: center;
}

.logo {
    width: 24.8rem;
    height: auto;
}

@media (max-width: 767px) {
    .collaboratorSection {
        padding: 0 1.6rem;
        margin-top: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 2rem;
        font-weight: bold;
        color: var(--intalk-text-strong);
    }

    .logoContainer {
        margin-top: 1.6rem;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.4rem;
        margin-bottom: 0.8rem;
    }

    .row:nth-child(5)::before {
        content: ""; /* 빈 공간 채우기 */
        flex: 0.5; /* 마지막 행에 남는 공간을 채워 균형 유지 */
    }

    .row:nth-child(5)::after {
        content: ""; /* 빈 공간 채우기 */
        flex: 0.5; /* 마지막 행에 남는 공간을 채워 균형 유지 */
    }

    .gridItem {
        flex: 1;
        text-align: center;
    }

    .logo {
        width: 100%;
        height: auto;
    }
}