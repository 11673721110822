@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Thin.woff') format('woff');
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200; /* ExtraLight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Light.woff') format('woff');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Regular.woff') format('woff');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Medium.woff') format('woff');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Bold.woff') format('woff');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800; /* ExtraBold */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Black.woff') format('woff');
  font-weight: 900; /* Black */
  font-style: normal;
}

/* Body 스타일 */
body {
  font-family: 'Pretendard', sans-serif;
  margin: 0;
}