.achievementSection {
    margin-top: 0.8rem;
    height: 41rem;
    background: linear-gradient(180deg, #FFF6C2 0%, #FCE37D 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10rem;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rndIcon {
    width: 6.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.graphIcon {
    width: 6.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.patentIcon {
    width: 15.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    margin-top: 1.6rem;
    font-size: 3.2rem;
    font-weight: bold;
}

.description {
    margin-top: 0.8rem;
    font-size: 1.6rem;
    text-align: center;
}

@media (max-width: 767px) {
    .achievementSection {
        margin-top: 2.4rem;
        padding: 4.8rem 0;
        height: auto;
        background: linear-gradient(180deg, #FFF6C2 0%, #FCE37D 100%);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6.4rem;
    }

    .rndIcon {
        width: 6.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .graphIcon {
        width: 6.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .patentIcon {
        width: 15.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .icon {
        width: 100%;
    }

    .title {
        margin-top: 1.6rem;
        font-size: 2rem;
        font-weight: bold;
    }

    .description {
        margin-top: 0.8rem;
        font-size: 1.6rem;
        text-align: center;
    }
}