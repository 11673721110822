.header {
    height: 8rem;
    border-bottom: 2px solid var(--intalk-primary-assistive);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12rem;
}

.logo {
    height: 4rem;
}

.blank {
    flex-grow: 1;
}

.ctaButton {
    border-radius: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--intalk-primary-normal);
    padding: 1.2rem 1.6rem;
}

.ctaButtonText {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
}

@media (max-width: 767px) {
    .header {
        height: 6.2rem;
        border-bottom: 2px solid var(--intalk-primary-assistive);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.6rem;
    }

    .logo {
        height: 3.2rem;
    }

    .blank {
        flex-grow: 1;
    }

    .ctaButton {
        border-radius: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--intalk-primary-normal);
        padding: 0.8rem 1.2rem;
    }

    .ctaButtonText {
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0;
    }
}