.foundingSection {
    margin-top: 16rem;
    height: 28rem;
    background-color: #FFF2BD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: row;
    gap: 16rem;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-weight: bold;
    font-size: 3.2rem;
}

.description {
    margin-top: 0.8rem;
    font-size: 1.6rem;
    text-align: center;
}

@media (max-width: 767px) {
    .foundingSection {
        margin-top: 8rem;
        height: 56.4rem;
        background-color: #FFF2BD;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 6.4rem;
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-weight: bold;
        font-size: 2rem;
    }

    .description {
        margin-top: 0.8rem;
        font-size: 1.6rem;
        text-align: center;
    }
}